import React, { PropsWithChildren } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { FHNextImage, ImageLoader } from "../FHNextImage";

export type HeaderSubNavButtonCardProps = {
  svgImgUrl: string;
  title: string;
  description: string;
  index: string;
  linkUrl?: string;
  linkLabel?: string;
  imageLoader?: ImageLoader;
  isDarkTheme?: boolean;
};

const useStyles = makeStyles((theme) => ({
  description: {
    paddingBottom: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(0, 1),
    height: "100%",
    "& img": {
      borderRadius: theme.spacing(1),
      maxWidth: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },

  ctaButton: {
    marginTop: theme.spacing(2),
  },
  noExpand: {
    "flex-grow": 0,
  },
  flexFix: {
    flexDirection: "row",

    "& > .MuiGrid-root": {
      flex: "0 1 100%",
    },
  },
  cardImageContainer: {
    position: "relative",
    width: "100%",
    minHeight: 143,
    paddingTop: "62.50%",
    margin: theme.spacing(2, 0),
  },
  cardImage: {
    margin: theme.spacing(2, 0),
  },
  subTitle: {
    fontSize: theme.typography.h4.fontSize,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: theme.typography.h4.lineHeight,
  },
  ctaButtonExp: {
    color: theme.palette.text.primary,
    borderColor: "#111",
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
      borderColor: "#111",
    },
  },
}));

export const HeaderSubNavButtonCard: React.FC<HeaderSubNavButtonCardProps> = ({
  svgImgUrl,
  title,
  description,
  index,
  linkUrl,
  linkLabel,
  imageLoader,
  isDarkTheme,
}: PropsWithChildren<HeaderSubNavButtonCardProps>) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classnames(classes.card)}
      key={`exlink-${index}`}
      data-testid="root"
      direction="column"
    >
      {title && (
        <Grid item xs className={classes.noExpand}>
          <Typography className={classnames(classes.subTitle)}>
            {title}
          </Typography>
        </Grid>
      )}
      {svgImgUrl && (
        <Grid
          item
          xs
          className={`${classes.noExpand} ${classes.cardImageContainer}`}
        >
          <div className={classes.cardImage}>
            <FHNextImage
              src={svgImgUrl}
              alt={title}
              loading="lazy"
              layout="fill"
              objectFit="cover"
              loader={imageLoader}
            />
          </div>
        </Grid>
      )}
      {description && (
        <Grid item xs>
          <Typography>{description}</Typography>
        </Grid>
      )}
      {linkUrl && linkLabel && (
        <Grid item xs className={classes.noExpand}>
          <Button
            className={classnames(classes.ctaButton, {
              [classes.ctaButtonExp]: isDarkTheme,
            })}
            color="secondary"
            href={linkUrl}
            fullWidth
            variant="outlined"
          >
            {linkLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
