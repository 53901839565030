import React, { PropsWithChildren, useRef } from "react";
import { Grid, Hidden, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HeaderLinkBase, HeaderSubnav, NavigationBase } from "../Header";
import { LocationsLineItems } from "../BookingForm";
import TextOverflowScroll from "../TextOverflowScroll";
import { isIpad } from "../../../../src/hooks/hooks";
import classNames from "classnames";

export type HeaderSubNavLinksCardProps = {
  index?: string;
  subnavLinks: HeaderSubnav;
  setHoverLocationId?(id: string): void;
  sortedLocations?: LocationsLineItems | undefined;
  hoverLocationId?: string;
  isBrowser?: boolean;
  isDarkTheme?: boolean;
};

const useStyles = makeStyles((theme) => ({
  subnavContainer: {
    padding: 0,
  },
  subnavHeader: {
    marginBottom: theme.spacing(2),
  },
  locationTitle: {
    position: "relative",
    padding: theme.spacing(0, 2),
    fontSize: theme.typography.h4.fontSize,
    fontFamily: theme.typography.h4.fontFamily,
    fontWeight: theme.typography.h4.fontWeight,
    lineHeight: theme.typography.h4.lineHeight,

    [theme.breakpoints.down(1140)]: {
      fontSize: theme.typography.body1.fontSize,
      lineHeight: theme.typography.body1.lineHeight,
    },
    "& strong": {
      [theme.breakpoints.down(1140)]: {
        position: "relative",
        zIndex: 2,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(0, 0.3),
      },
      [theme.breakpoints.up("xl")]: {
        fontWeight: 500,
        fontFamily: theme.typography.fontFamily,
      },
    },
    "&:after": {
      [theme.breakpoints.down(1140)]: {
        position: "absolute",
        content: '""',
        width: "calc(100% + 20px)",
        height: 1,
        left: theme.spacing(-2),
        top: "50%",
        backgroundColor: theme.palette.divider,
        zIndex: 1,
      },
    },
  },
  subnavLinks: {
    padding: theme.spacing(0, 2),
    "& li": {
      listStyleType: "none",
    },
    "& p": {
      margin: theme.spacing(1, 0),
    },
  },
  locationLink: {
    color: theme.palette.text.primary,
  },
}));

export const HeaderSubNavLinksCard: React.FC<HeaderSubNavLinksCardProps> = ({
  index,
  subnavLinks,
  setHoverLocationId = () => {},
  sortedLocations,
  hoverLocationId,
  isBrowser,
  isDarkTheme,
}: PropsWithChildren<HeaderSubNavLinksCardProps>) => {
  const classes = useStyles();

  const getList = (link: HeaderLinkBase, key: number) => {
    const parentElementContentRef = useRef(null);
    const textOverflowTextWidth =
      parentElementContentRef?.current?.clientWidth || 0;
    const textOverflowHiddenTextWidth =
      parentElementContentRef?.current?.nextElementSibling?.clientWidth || 0;
    const containerWidth =
      textOverflowTextWidth > textOverflowHiddenTextWidth
        ? textOverflowHiddenTextWidth
        : 0;

    const locationName =
      link?.label?.split(",")?.[0]?.toLowerCase()?.replaceAll(" ", "") || "";
    const isHighlighted =
      sortedLocations &&
      sortedLocations[locationName] &&
      sortedLocations[locationName].id === hoverLocationId
        ? true
        : false;

    return (
      <Typography
        key={`subNavLink-${key}`}
        onMouseEnter={() => {
          if (sortedLocations && locationName) {
            sortedLocations[locationName] &&
              setHoverLocationId(sortedLocations[locationName].id);
          }
        }}
        onMouseLeave={() => {
          setHoverLocationId && setHoverLocationId("");
        }}
      >
        <Link
          underline="none"
          color="secondary"
          href={link?.url ? link?.url : ""}
          className={classNames({
            [classes.locationLink]: isDarkTheme,
          })}
        >
          <strong>
            {isBrowser && !isIpad() ? (
              <Hidden mdDown>
                <TextOverflowScroll
                  overFlowText={link?.label || ""}
                  containerWidth={containerWidth}
                  isHighlighted={isHighlighted}
                  textColour={"secondary"}
                  parentElementReference={parentElementContentRef}
                  isDarkTheme={isDarkTheme}
                />
              </Hidden>
            ) : (
              `${link?.label || ""}`
            )}
          </strong>
        </Link>
      </Typography>
    );
  };

  return (
    <Grid
      data-testid="root"
      item
      xs
      className={classes.subnavContainer}
      key={`subNav-${index}`}
    >
      {(subnavLinks.title || subnavLinks.text) && (
        <Typography className={classNames(classes.locationTitle)}>
          <strong>{subnavLinks.title || subnavLinks.text}</strong>
        </Typography>
      )}

      {subnavLinks.description && (
        <Typography>{subnavLinks.description}</Typography>
      )}

      <ul className={classes.subnavLinks}>
        {subnavLinks?.link && Array.isArray(subnavLinks.link) ? (
          <>
            {subnavLinks.link.map((link: NavigationBase, index: number) => (
              <li>
                {!!link && !!link?.text && (
                  <Typography key={`subNavLink-${index}`} color="secondary">
                    <Link
                      color={"secondary"}
                      href={link.link || ""}
                      underline="none"
                      className={classNames({
                        [classes.locationLink]: isDarkTheme,
                      })}
                    >
                      <b>{link.text}</b>
                    </Link>
                  </Typography>
                )}
              </li>
            ))}
          </>
        ) : (
          <>
            {subnavLinks.links &&
              subnavLinks.links.map((link: HeaderLinkBase, key: number) => (
                <li key={`sub-nav-link-${key}`}>{getList(link, key)}</li>
              ))}
          </>
        )}
      </ul>
    </Grid>
  );
};
